/* eslint-disable @shopify/typescript/prefer-pascal-case-enums */
import type {MetricsConfig} from '@shopify/opentelemetry-sdk';

enum MetricInstrumentType {
  Histogram = 'Histogram',
  Counter = 'Counter',
  UpDownCounter = 'UpDownCounter',
}

enum ValueType {
  INT = 0,
  DOUBLE = 1,
}

export enum TelemetryMetricId {
  InvalidStorefrontOrigin = 'shop_js_invalid_storefront_origin',
  RequestShowCalledBeforeIframeLoaded = 'shop_js_request_show_called_before_iframe_loaded',
  HandleSilentError = 'shop_js_handle_silent_error',
  MonorailProducerError = 'shop_js_monorail_producer_error',
}

export const metrics: MetricsConfig = {
  [TelemetryMetricId.InvalidStorefrontOrigin]: {
    type: MetricInstrumentType.Counter,
    description: 'Number of times the storefront origin is invalid',
    valueType: ValueType.INT,
  },
  [TelemetryMetricId.RequestShowCalledBeforeIframeLoaded]: {
    type: MetricInstrumentType.Counter,
    description:
      'Number of times requestShow is called before iframe is loaded',
    valueType: ValueType.INT,
  },
  [TelemetryMetricId.HandleSilentError]: {
    type: MetricInstrumentType.Counter,
    description: 'Number of times silent errors are handled',
    valueType: ValueType.INT,
  },
  [TelemetryMetricId.MonorailProducerError]: {
    type: MetricInstrumentType.Counter,
    description: 'Number of times Monorail Producer failed to send event',
    valueType: ValueType.INT,
  },
};
