import {PositionBehaviorType} from '../utils';

import {CORNER_CONTENT_SHIFT} from './constants-compact';

/**
 * Get keyframes to fade out elements
 * @returns {Array} The keyframes for this animation
 */
export function getFadeOutKeyframes(): Keyframe[] {
  return [{opacity: 1}, {opacity: 0}];
}

/**
 * Get keyframes to fade in elements
 * @returns {Array} The keyframes for this animation
 */
export function getFadeInKeyframes(): Keyframe[] {
  return [{opacity: 0}, {opacity: 1}];
}

/**
 * Get padding amount for the document element when the modal is opened
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {string} The padding amount
 */
export function getDocumentPadding(behavior: PositionBehaviorType): string {
  if (behavior === PositionBehaviorType.Corner) {
    return `${CORNER_CONTENT_SHIFT}px`;
  }
  return '';
}

/**
 * Get transform value for modal animation
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {string} The transform value
 */
function getModalTransform(behavior: PositionBehaviorType): string {
  if (behavior === PositionBehaviorType.Mobile) {
    return 'translate(0, 100%)';
  }
  if (behavior === PositionBehaviorType.Corner) {
    return 'translate(0, -120px)';
  }
  return '';
}

/**
 * Get keyframes to close the element
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {Array} The keyframes for this animation
 */
export function getClosingKeyframes(
  behavior: PositionBehaviorType,
): Keyframe[] {
  if (behavior === PositionBehaviorType.Dynamic) {
    return [
      {
        transform: 'scale(1)',
        opacity: 1,
      },
      {
        transform: 'scale(0)',
        opacity: 0,
      },
    ];
  }

  return [
    {
      transform: 'translate(0, 0)',
      opacity: 1,
    },
    {transform: getModalTransform(behavior), opacity: 0},
  ];
}

/**
 * Get keyframes to open the element
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {Array} The keyframes for this animation
 */
export function getOpeningKeyframes(
  behavior: PositionBehaviorType,
): Keyframe[] {
  if (behavior === PositionBehaviorType.Dynamic) {
    return [
      {
        transform: 'scale(0)',
        opacity: 0,
      },
      {
        transform: 'scale(1)',
        opacity: 1,
      },
    ];
  }

  return [
    {
      transform: getModalTransform(behavior),
      opacity: 0,
    },
    {transform: 'none', opacity: 1},
  ];
}

/**
 * Get keyframes for the overlay when the modal is being closed
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {Array} The keyframes for this animation
 */
export function getOverlayClosingKeyframes(
  behavior: PositionBehaviorType,
): Keyframe[] {
  if (behavior === PositionBehaviorType.Corner) {
    return [
      {
        boxShadow: `inset 0 ${CORNER_CONTENT_SHIFT}px #000`,
        opacity: 1,
      },
      {
        boxShadow: 'inset 0 0 #000',
        opacity: 0,
      },
    ];
  }

  return getFadeOutKeyframes();
}

/**
 * Get keyframes for the overlay when the modal is being opened
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {Array} The keyframes for this animation
 */
export function getOverlayOpeningKeyframes(
  behavior: PositionBehaviorType,
): Keyframe[] {
  if (behavior === PositionBehaviorType.Corner) {
    return [
      {
        boxShadow: 'inset 0 0 #000',
        opacity: 0,
      },
      {
        boxShadow: `inset 0 ${CORNER_CONTENT_SHIFT}px #000`,
        opacity: 1,
      },
    ];
  }

  return getFadeInKeyframes();
}

/**
 * Get keyframes default options
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @returns {object} The keyframes for this animation
 */
export function getAnimationDefaultOptions(
  behavior: PositionBehaviorType,
): KeyframeAnimationOptions {
  const defaultDuration = behavior === PositionBehaviorType.Corner ? 300 : 400;
  return {
    easing:
      behavior === PositionBehaviorType.Corner
        ? 'cubic-bezier(0,0,0.58,1)'
        : 'cubic-bezier(0.32,0.72,0,1)',
    duration: window.matchMedia('(prefers-reduced-motion: reduce)').matches
      ? 0
      : defaultDuration,
  };
}

/**
 * Get keyframes options for modal animation
 * @param {PositionBehaviorType} behavior Inform how the transform should work for the modal
 * @param {object} defaultOptions The default options
 * @returns {object} The options for modal animation
 */
export function getAnimationModalOptions(
  behavior: PositionBehaviorType,
  defaultOptions: KeyframeAnimationOptions,
): KeyframeAnimationOptions {
  if (behavior === PositionBehaviorType.Corner) {
    // Note: defaultOptions.duration is 0 when the user prefers reduced motion
    return {
      ...defaultOptions,
      easing: 'cubic-bezier(0,.95,.21,1.1)',
      delay: defaultOptions.duration ? 400 : 0,
      duration: defaultOptions.duration ? 550 : 0,
    };
  }

  return defaultOptions;
}

/**
 * Get all focusable elements for a given parent element, considering children provided to slot elements
 * @param {HTMLSlotElement} slot The slot element to get focusable elements from
 * @returns {Array} The focusable elements
 */
export function getFocusableElements(slot: HTMLSlotElement): Element[] {
  const selector =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

  return Array.from(slot.assignedElements()).reduce<Element[]>(
    (focusable, parentElement) => {
      const focusableElements = Array.from(
        parentElement.querySelectorAll(selector),
      );
      if (parentElement.matches(selector)) {
        focusableElements.unshift(parentElement);
      }
      return [...focusable, ...focusableElements];
    },
    [],
  );
}
