/**
 * Sets the value of the given cookie, with the given duration.
 * @param {string} name The name of the cookie.
 * @param {string} value The value of the cookie.
 * @param {number} duration The duration of the cookie. If no duration is given, the cookie will expire at the end of the session.
 */
export function setCookie(
  name: string,
  value: string,
  duration?: number,
): void {
  const expires = duration
    ? new Date(Date.now() + duration).toUTCString()
    : 'session';
  document.cookie = `${name}=${value};expires=${expires};path=/`;
}

/**
 * Returns the value of the cookie with the given key.
 * @param {string} key The name of the cookie.
 * @returns {string | null} The value of the cookie or null if it doesn't exist.
 */
export function getCookie(key: string): string | null {
  try {
    const value = new RegExp(`(${key})=([^;]+)`).exec(document.cookie);
    return value ? value[2] : null;
  } catch (_ignore) {
    return null;
  }
}

/**
 *
 * @param {string} name The name of the cookie.
 * @param {string} path The path of the page.
 * @param {string} domain The domain of the page.
 */
export function deleteCookie(name: string, path?: string, domain?: string) {
  if (getCookie(name)) {
    document.cookie = `${name}=${path ? `;path=${path}` : ''}${
      domain ? `;domain=${domain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}
