import {recordOpentel, TelemetryMetricId} from 'dynamicImports/opentelemetry';

// eslint-disable-next-line no-process-env
export const PAY_AUTH_DOMAIN = process.env.PAY_AUTH_DOMAIN;
// eslint-disable-next-line no-process-env
export const PAY_AUTH_DOMAIN_ALT = process.env.PAY_AUTH_ALT_DOMAIN;
// eslint-disable-next-line no-process-env
export const SHOP_WEBSITE_DOMAIN = process.env.SHOP_WEBSITE_DOMAIN;

export enum InvalidUrlError {
  UsingLocalhost = 'using_localhost',
  NotUsingHttps = 'not_using_https',
  HasPath = 'has_path',
  HasHash = 'has_hash',
  HasSearch = 'has_search',
  InvalidUrl = 'invalid_url',
}

/**
 * Checks if the storefront origin is valid.
 * @param {string} storefrontOrigin - The storefront origin to validate.
 * @returns {boolean} True if the storefront origin is valid, false otherwise.
 */
export function validateStorefrontOrigin(storefrontOrigin: string): boolean {
  let error;
  try {
    const url = new URL(storefrontOrigin);
    if (
      (url.hostname === 'localhost' || url.hostname === '127.0.0.1') &&
      url.protocol !== 'https:'
    ) {
      error = InvalidUrlError.UsingLocalhost;
    } else if (url.protocol !== 'https:') {
      error = InvalidUrlError.NotUsingHttps;
    } else if (url.pathname !== '/') {
      error = InvalidUrlError.HasPath;
    } else if (url.hash) {
      error = InvalidUrlError.HasHash;
    } else if (url.search) {
      error = InvalidUrlError.HasSearch;
    }
  } catch (_) {
    error = InvalidUrlError.InvalidUrl;
  } finally {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`[shop-js] Invalid storefront origin: ${storefrontOrigin}`);
      recordOpentel(TelemetryMetricId.InvalidStorefrontOrigin, 1, {
        error,
      });
    }
  }

  return error === undefined;
}
