import {MAX_Z_INDEX} from 'common/constants';

import {SMALL_SCREEN_WIDTH} from '../common/shop-sheet-modal/constants';

export const SHOP_FOLLOW_FLOW = 'follow';

const HEIGHT = '0';
const WIDTH = '100%';

export const SHOP_FOLLOW_BUTTON_HTML = `<style>
  :host {
    display:inline-block;
    font-family: -apple-system,BlinkMacSystemFont,San Francisco,Roboto,Segoe UI,Helvetica Neue,sans-serif !important;
    font-style: normal !important;
    line-height: normal;
  }
  iframe {
    border:none;
    height:${HEIGHT};
    width:${WIDTH};
  }
  store-logo {
    margin: 16px 0 29px;
  }

  .hidden {
    display: none;
  }

  @media screen and (min-width: ${SMALL_SCREEN_WIDTH + 1}px) {
    store-logo {
      margin-top: 38px;
    }
  }
  </style>`;

export const createGetAppButtonHtml = (
  link: string,
  buttonText: string,
) => `<style>
  .fos-get-app-button {
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    background: #5433EB;
    border-radius: 6px;
    align-items: center;
    padding: 12px;
    text-decoration: none;
    color: #ffffff;
    line-height: 24px;
  }</style><a href="${link}" class="fos-get-app-button">${buttonText}</a>`;

export const createScanCodeTooltipHtml = (
  description: string,
  qrCodeUrl: string,
  qrCodeAltText: string,
) => `
<style>
  .fos-tooltip {
    position: relative;
    display: flex;
    left: -10px;
  }

  .fos-tooltip-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: visible;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);
    border-radius: 14px;
    position: absolute;
    width: 220px;
    bottom: 78px;
    z-index: ${MAX_Z_INDEX} !important;
  }

  .fos-tooltip-popup::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 18px;
    margin-left: -3px;
    border-width: 13px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    transform: scale(1, 0.75) translateY(-7px);
  }

  .fos-tooltip-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483646 !important;
    will-change: opacity, transform;
  }

  .fos-tooltip-text {
    color: #000;
    font-size: 16px;
    line-height: 18px;
    padding: 24px 24px 16px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fos-tooltip-code-frame {
    background: #ffffff;
    forced-color-adjust: none;
  }

  .fos-tooltip-code {
    width: 147px;
    height: 147px;
    display: block;
  }

  .fos-tooltip-shop-logo {
    padding: 16px 0 24px 0;
    display: flex;
    justify-content: center;
  }

  .fos-tooltip-shop-logo shop-logo {
    display: inline-flex;
  }

  .fos-tooltip-hidden {
    height: 0 !important;
    border: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
    overflow: hidden;
  }

  @media (forced-colors: active) {
    .fos-tooltip-popup {
      border: 1px solid;
    }

    .fos-tooltip-popup::after {
      display: none;
    }
  }
</style>
<div class="fos-tooltip-overlay"></div>
<div class="fos-tooltip-popup">
    <div class="fos-tooltip-text">${description}</div>
    <div class="fos-tooltip-code-frame">
      <img src="${qrCodeUrl}" class="fos-tooltip-code" alt="${qrCodeAltText}"/>
    </div>
    <div class="fos-tooltip-shop-logo">
      <shop-logo role="img" size="20" color="brand" label="Shop"/>
    </div>
  </div>
`;
