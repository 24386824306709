import {colors} from '../colors';

export class AnimatedShopLogo extends HTMLElement {
  #rootElement: ShadowRoot;

  constructor() {
    super();

    this.#rootElement = this.attachShadow({mode: 'open'});
  }

  connectedCallback() {
    const template = document.createElement('template');
    const size = this.getAttribute('size') || '';
    const color = this.getAttribute('color') || '';
    const label = this.getAttribute('label') || '';
    template.innerHTML = getTemplateContents(size, color, label);
    this.#rootElement.appendChild(template.content.cloneNode(true));
  }
}

/**
 * @param {string} size the size of the icon
 * @param {string} colorName the desired color key for the icon
 * @param {string} label the label to use with the logo
 * @returns {string} HTML content for the logo.
 */
function getTemplateContents(size: string, colorName = 'brand', label: string) {
  const stroke: string = colors[colorName];

  return `
    <style>
      @keyframes revealIcon {
        to {
          stroke-dashoffset: 408;
        }
      }

      @keyframes infiniteSpin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      .Wrapper {
        @media screen and (-ms-high-contrast: active),
          screen and (-ms-high-contrast: none) {

        }
      }

      .ShopLogo {
        stroke-dashoffset: 136;
        stroke-dasharray: 136;
        animation: revealIcon 1.3s ease-in-out 0s reverse infinite;

        // Target IE11 and provide a fallback animation
        @media screen and (-ms-high-contrast: active),
          screen and (-ms-high-contrast: none) {
          stroke-dasharray: 0;
        }
      }
    </style>
    <svg
      class="Wrapper"
      fill="none"
      width=${size}
      height=${size}
      viewBox="0 0 52 58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>${label}</title>
      <path
        class="ShopLogo"
        d="M3 13C5 11.75 10.4968 6.92307 21.5 6.4999C34.5 5.99993 42 13 45 23C48.3 34 42.9211 48.1335 30.5 51C17.5 54 6.6 46 6 37C5.46667 29 10.5 25 14 23"
        stroke=${stroke}
        stroke-width="11"
      />
    </svg>
  `;
}

if (!customElements.get('animated-shop-logo')) {
  customElements.define('animated-shop-logo', AnimatedShopLogo);
}

/**
 * helper function which creates an animated Shop logo and returns it to the parent
 * @returns {AnimatedShopLogo} a new animated Shop logo
 */
export function createAnimatedShopLogo(): AnimatedShopLogo {
  const animatedShopLogo = document.createElement('animated-shop-logo');

  animatedShopLogo.setAttribute('role', 'img');
  animatedShopLogo.setAttribute('size', '22');

  return animatedShopLogo as AnimatedShopLogo;
}
