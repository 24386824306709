/**
 * @file Abstract base class that all web component classes should extend.
 * Forces implementation of key web component callbacks, implements common
 * logic useful for all web components.
 * For more information regarding implementing custom components, see: https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements
 */
export default abstract class WebComponent extends HTMLElement {
  constructor() {
    super();
  }

  /**
   * Custom component lifecycle methods
   */

  /**
   * Invoked each time the custom element is moved to a new document.
   */
  abstract connectedCallback(): void;

  /**
   * Invoked each time the custom element is disconnected from the document's DOM.
   */
  abstract disconnectedCallback(): void;

  /**
   * Invoked each time one of the custom element's attributes is added, removed, or changed.
   * @param {string} name Attribute name
   * @param {string} oldValue Previous value
   * @param {string | null} newValue New value or `null` if the attribute is removed.
   */
  abstract attributeChangedCallback(
    name: string,
    oldValue: string,
    newValue: string | null,
  ): void;

  /**
   * Update attribute with a new value
   * @param {string} name Attribute name
   * @param {string} [value] New value
   */
  protected updateAttribute(name: string, value?: string): void {
    if (this.getAttribute(name) === value) return;
    if (value) {
      this.setAttribute(name, value);
    } else {
      this.removeAttribute(name);
    }
  }

  /**
   * Get attribute as a boolean. Disregards the value - so long as the attribute is passed, it is truthy.
   * According to the HTML Spec: The presence of a boolean attribute on an element represents the true value,
   * and the absence of the attribute represents the false value.
   * @param {string} name Attribute name
   * @returns {boolean} `true` if the attribute is passed in, `false` otherwise.
   */
  protected getBooleanAttribute(name: string): boolean {
    const value = this.getAttribute(name);
    if (value === null || value === undefined) return false;
    return true;
  }

  /**
   * Dispatches a CustomEvent from a web component. Use any time a web component needs
   * to communicate with the parent app. The event will have `composed:true` to propagate
   * across the Shadow DOM boundary.
   * @param {string} type String to identify the event. Naming convention: all lowercase. When used in React
   * the handler will just prepend "on" to this name.
   * @param {object} detail Any data to be returned to the consumer of the event, in the event.detail field.
   */
  protected dispatchCustomEvent(type: string, detail?: any) {
    const event = new CustomEvent(type, {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail,
    });
    this.dispatchEvent(event);
  }
}
