import {createSafeWrapperInBody} from './createSafeWrapperInBody';
import {ShopSheetModal, createShopSheetModal} from './shop-sheet-modal';

export interface SheetModalManager {
  sheetModal: ShopSheetModal;
  shadowRoot: ShadowRoot;
  setNametagSuffix(suffix: string): void;
  destroy(): void;
}

interface SheetModalBuilder {
  withInnerHTML(innerHTML: string): SheetModalBuilder;
  build(): SheetModalManager;
}

/**
 * This builder is used to create a sheet modal on the page in a consistent way.
 * - withInnerHTML: allows a hook to add additional content to a wrapper div's shadow root, useful for CSS.
 * - build: creates the sheet modal, appends it to the shadow root, and appends the wrapper div to the body.
 *
 * The build method will return a SheetModalManager which can be used to reference the underlying sheetModal element
 * and a cleanup method called `destroy` that will remove the modal from the DOM.
 * @returns {SheetModalBuilder} - The sheet modal builder
 */
export function sheetModalBuilder(): SheetModalBuilder {
  const wrapper = createSafeWrapperInBody();
  const shadowRoot = wrapper.attachShadow({mode: 'open'});

  return {
    withInnerHTML(innerHTML: string) {
      shadowRoot.innerHTML = innerHTML;

      return this;
    },
    build(): SheetModalManager {
      document.body.appendChild(wrapper);

      shadowRoot.appendChild(createShopSheetModal());

      return {
        get sheetModal() {
          return shadowRoot.querySelector('shop-sheet-modal') as ShopSheetModal;
        },
        get shadowRoot() {
          return shadowRoot;
        },
        setNametagSuffix(testId: string): void {
          wrapper.setAttribute('data-nametag', `shop-sheet-modal-${testId}`);
        },
        destroy() {
          wrapper.remove();
        },
      };
    },
  };
}
