import {MAX_Z_INDEX} from 'common/constants';
import {v4} from 'uuid';

/**
 * Creates a safe wrapper in the body to display the modal.
 * @returns {HTMLDivElement} - The wrapper element
 */
export function createSafeWrapperInBody() {
  const wrapper = document.createElement('div');
  wrapper.id = `shop-sheet-modal-wrapper-${v4()}`;
  wrapper.style.setProperty('all', 'initial');
  wrapper.style.setProperty('position', 'absolute');
  wrapper.style.setProperty('z-index', MAX_Z_INDEX);
  wrapper.style.setProperty('overflow', 'visible');
  wrapper.style.setProperty('display', 'block');

  return wrapper;
}
