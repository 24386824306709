import {logInfo} from 'common/logging';

import Bugsnag from '../bugsnag';

import {Dictionary, LocaleDictionary, SupportedLocale} from './types';

interface TranslateOptions {
  defaultValue?: string;
  [key: string]: any;
}

export class I18n {
  static allowedLocales: SupportedLocale[] = [
    'en',
    'bg-BG',
    'cs',
    'da',
    'de',
    'el',
    'es',
    'fi',
    'fr',
    'hi',
    'hr-HR',
    'hu',
    'id',
    'it',
    'ja',
    'ko',
    'lt-LT',
    'ms',
    'nb',
    'nl',
    'pl',
    'pt-BR',
    'pt-PT',
    'ro-RO',
    'ru',
    'sk-SK',
    'sl-SI',
    'sv',
    'th',
    'tr',
    'vi',
    'zh-CN',
    'zh-TW',
  ];

  static getDefaultLanguage(): SupportedLocale {
    const buildLocale =
      // eslint-disable-next-line no-process-env
      (process.env.BUILD_LOCALE as SupportedLocale) || undefined;
    if (buildLocale) {
      return buildLocale;
    }

    try {
      const shopifyLocale =
        document.documentElement.lang || window.Shopify?.locale;
      if (shopifyLocale) {
        const parsedLocale = new Intl.Locale(shopifyLocale);
        if (I18n.allowedLocales.includes(shopifyLocale as SupportedLocale)) {
          return shopifyLocale as SupportedLocale;
        } else if (
          I18n.allowedLocales.includes(parsedLocale.language as SupportedLocale)
        ) {
          // fallback on the unsupported language, pick the language without the region (e.g. 'en-GB' -> 'en')
          return parsedLocale.language as SupportedLocale;
        } else {
          logInfo(
            `Unsupported locale: "${shopifyLocale}". Falling back to "en".`,
          );
        }
      }

      for (const preferredLanguage of navigator.languages) {
        if (
          I18n.allowedLocales.includes(preferredLanguage as SupportedLocale)
        ) {
          return preferredLanguage as SupportedLocale;
        }

        const parsedLocale = new Intl.Locale(preferredLanguage);
        if (
          I18n.allowedLocales.includes(parsedLocale.language as SupportedLocale)
        ) {
          return parsedLocale.language as SupportedLocale;
        }
      }
    } catch (error) {
      // these usually happens when the Intl API has detected an unsupported locale (e.g. 'mamma mia')
    }
    return 'en';
  }

  #translations: Dictionary;
  #locale: SupportedLocale =
    // eslint-disable-next-line no-process-env
    (process.env.BUILD_LOCALE as SupportedLocale) || I18n.getDefaultLanguage();

  constructor(translations: Dictionary) {
    this.#translations = translations;
  }

  get locale(): SupportedLocale {
    return this.#locale;
  }

  set locale(locale: SupportedLocale) {
    if (I18n.allowedLocales.includes(locale)) {
      this.#locale = locale;
    }
  }

  #needsPluralize(translations: LocaleDictionary | string, locals: any = {}) {
    return typeof translations !== 'string' && locals.count !== 'undefined';
  }

  #getPluralizeKey(translations: LocaleDictionary | string, count: number) {
    let key = count === 1 ? 'one' : 'other';

    if (
      count === 0 &&
      typeof translations !== 'string' &&
      translations.zero !== 'undefined'
    ) {
      key = 'zero';
    }

    return key;
  }

  #render(translation: string, locals: TranslateOptions = {}): string {
    const matches = translation.match(/\{.+?\}/g);

    if (matches) {
      return matches.reduce((res, match) => {
        const key = match.replace(/\{(.*)\}/, '$1');
        if (locals[key]) {
          return res.replace(match, locals[key]);
        } else {
          Bugsnag.notify(
            new Error(
              `i18n: Missing translation key '${key}' for '${translation}'`,
            ),
          );
        }
        return res;
      }, translation);
    }

    return translation;
  }

  translate(scope: string, locals: TranslateOptions = {}): string {
    const scopes = scope.split('.');
    let ret: LocaleDictionary | string | undefined =
      this.#translations[this.#locale];

    try {
      // Traverse through the translation data one "scope" at a time
      for (const scope of scopes) {
        switch (typeof ret) {
          case 'object':
            ret = ret[scope];
            break;
          case 'string':
          case 'undefined':
            throw new ReferenceError();
        }
      }

      // No key? Throw an error
      if (typeof ret === 'undefined') {
        throw new ReferenceError();
      }

      // Do we need to pluralize?
      if (this.#needsPluralize(ret, locals)) {
        ret = (ret as LocaleDictionary)[
          this.#getPluralizeKey(ret, locals.count)
        ];
      }

      return this.#render(ret as string, locals);
    } catch (err) {
      // If we can't find a translation, return the default value if one exists
      if (locals.defaultValue) {
        return locals.defaultValue;
      }

      // Otherwise, return the scope
      return scope;
    }
  }

  isEnglish(): boolean {
    return this.#locale === 'en';
  }
}
