import type {Attributes} from '@shopify/opentelemetry-sdk';
import Bugsnag from 'common/bugsnag';

import {getOtelServiceSingletonWithRetries} from './singleton';
import {TelemetryMetricId} from './metrics';

export const record: (
  telemetryMetricId: TelemetryMetricId,
  value: number,
  tags: Attributes,
) => void = (telemetryMetricId, value, tags) => {
  // eslint-disable-next-line no-process-env
  if (!process.env.ENABLE_OPENTEL) return;

  getOtelServiceSingletonWithRetries()
    .then((otelService) => {
      otelService.record(telemetryMetricId, value, tags);
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};
