import {computePosition, autoUpdate, Side} from '@floating-ui/dom';

import {
  PositionData,
  config,
  PositionBehaviorType,
  PositionOverrides,
} from './position.behavior';

interface PositionOptions {
  anchorElement: Element | null | undefined;
  floatingElement: HTMLElement;
  overlayElement: HTMLElement;
  behavior?: PositionBehaviorType;
}

interface PositionReturn {
  cleanup: () => void;
  updatePosition: () => Promise<PositionData | null>;
}

const noop: PositionReturn = {
  cleanup: () => {},
  updatePosition: async () => Promise.resolve(null),
};

/**
 * @param {PositionOptions} options - Options for the positioning function
 * @param {Element} options.anchorElement- The element to attach the floating element to
 * @param {HTMLElement} options.floatingElement - The floating element
 * @param {HTMLElement} options.overlayElement - The overlay element
 * @param {PositionBehaviorType} options.behavior - Center, Dynamic, Mobile
 * @param {PositionOverrides} positionOverrides - enables the overriding of the position of the modal
 * @param {Side} anchorPosition - The side of the anchor element to attach to, also disables fallback positions
 * @returns {PositionReturn} - Functions to cleanup and update positioning
 */
export function position(
  {
    anchorElement,
    floatingElement,
    overlayElement,
    behavior = PositionBehaviorType.Center,
  }: PositionOptions,
  positionOverrides: PositionOverrides = {},
  anchorPosition?: Side,
): PositionReturn {
  if (floatingElement === null) {
    return noop;
  }

  const behaviorConfig =
    (anchorElement === null || anchorElement === undefined) &&
    ![PositionBehaviorType.Mobile, PositionBehaviorType.Corner].includes(
      behavior,
    )
      ? config[PositionBehaviorType.Center](
          floatingElement,
          overlayElement,
          positionOverrides,
        )
      : config[behavior](
          floatingElement,
          overlayElement,
          positionOverrides,
          anchorPosition,
        );

  // Going to assume that if we aren't passed the anchor we can just set it to the body
  // This is because Dynamic positioning is the only one that really uses the floating-ui
  // library to compute position. The other two behaviors are just set up to fit the shape
  // and the anchor doesn't really matter.
  const referenceElement = anchorElement || document.body;
  const updatePosition = async () => {
    const returnVal = await computePosition(
      referenceElement,
      floatingElement,
      behaviorConfig.config,
    );

    return behaviorConfig.fn(returnVal);
  };

  const cleanup = autoUpdate(
    referenceElement,
    floatingElement,
    updatePosition,
    /*
      Changed the `ancestorScroll` to false from its default values.
      We don't want the modal to reposition on scrolling.
      We do want to reposition when an ancestor resizes, ie. the browser
    */
    {
      ancestorScroll: false,
      ancestorResize: true,
      elementResize: true,
      animationFrame: false,
    },
  );

  return {
    cleanup,
    updatePosition,
  };
}
